<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <banner
          :title="category"
          :breadcrumb="[{ label: 'Category ' }, { label: `${serviceName}` }]"
        >
        </banner>
        <div class="row" style="margin-top: -45px">
          <div
            class="col-xl-6 col-lg-6 col-md-6 col-12"
            style="padding: 5px 5px 5px 5px; height: 100%"
          >
            <div class="card" style="height: 100%">
              <div class="card-body">
                <h4><strong style="color: #00364f">Step-3 : Upload Doccuments</strong></h4>
                <!-- <div class="progress">
                  <div
                    style="background-color: #f21300; width: 20%"
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="40"
                    aria-valuemin="40"
                    aria-valuemax="100"
                  >
                    20%
                  </div>
                </div> -->

                <div class="row">
                  <div
                    class="col-xl-6 p-1"
                    v-for="(docc, index) in allDoccs"
                    :key="index"
                  >
                    <label>{{ docc.document }}</label>
                    <input
                      type="file"
                      class="form-control"
                      @change="uploadDocuments($event, docc)"
                    />
                    <small v-if="docc.format">Format: {{ docc.format }}</small
                    ><br />
                    <small v-if="docc.size">size: {{ docc.size }} kb</small>
                    <small v-if="docc.width">Format: {{ docc.width }}px</small><br />
                    <small v-if="docc.height">size: {{ docc.height }}px</small>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-12 p-1"
                    v-for="(link, index) in clientservicedocuments"
                    :key="index"
                  >
                    <!-- <span @click="downloadImg(link)">
                                            Download {{link.caption}}
                                          </span> -->

                    <a target="_blank" :href="downloadImg(link)" style="color:#f21000"> Download {{ link.caption }}</a>
                  </div>
                  <div class="col-6">
                    <!-- <button
                        type="button"
                        @click="redirectBack"
                        class="btn btn-sm text-white"
                        style="background-color: #00364f; width: 70%"
                      >
                        <strong>BACK</strong>
                      </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px;">
            <div class="card" >
              <div class="card-body">
                <h4><strong style="color: black">User Payment</strong></h4>
                <form @submit.prevent="clientPayServiceAmount()">
                  <div class="row">
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Amount  <small style="color:#f21000">*</small></label>
                      <input
                      required
                      min="1"
                      :max="serviceCharge"
                        type="number"
                        class="form-control"
                        v-model="userPaymentForm.amt"
                        placeholder="Amount"
                      />
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Date <small style="color:#f21000">*</small></label>
                      <input
                      required
                        type="date"
                        v-model="userPaymentForm.date"
                        class="form-control"
                        placeholder="Date"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.date">{{
                          userPaymentForm.errors.error.date[0]
                        }}</span></span
                      ></small
                    >
                      <span style="color: #f21000" id="doBDate1"></span>
                    </div>
                    <div
                      class="col-xl-12 col-md-12 col-lg-12 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Payment Mode <small style="color:#f21000">*</small></label>

                      <select
                      required
                        v-model="userPaymentForm.paymentMode"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected disabled>Payment Mode...</option>
                        <option value="Cash">Cash</option>
                        <option value="Online">Online</option>
                        <option value="Cheque">Cheque</option>
                      </select>

                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.paymentMode">{{
                          userPaymentForm.errors.error.paymentMode[0]
                        }}</span></span
                      ></small
                    >
                    </div>
                    <!-- <div
                    v-if="userPaymentForm.paymentMode == 'Online'"
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Transection Number</label>
                      <input
                        v-model="userPaymentForm.txn"
                        type="text"
                        class="form-control"
                        placeholder="Transection Number"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.txn">{{
                          userPaymentForm.errors.error.txn[0]
                        }}</span></span
                      ></small
                    >
                    </div> -->

                    <!-- <div  v-if="userPaymentForm.paymentMode == 'Online'"
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Transection Ref. No.</label>
                      <input
                        v-model="userPaymentForm.txnRefNo"
                        type="text"
                        class="form-control"
                        placeholder="Transection Reference Number"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.txnRefNo">{{
                          userPaymentForm.errors.error.txnRefNo[0]
                        }}</span></span
                      ></small
                    >
                    </div> -->

                  

                    <div
                      v-if="userPaymentForm.paymentMode == 'Cheque'"
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Cheque No.</label>
                      <input
                        v-model="userPaymentForm.checkno"
                        type="number"
                        class="form-control"
                        placeholder="Cheque No."
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.checkno">{{
                          userPaymentForm.errors.error.checkno[0]
                        }}</span></span
                      ></small
                    >
                    </div>

                    <!-- <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                      v-if="userPaymentForm.paymentMode == 'Online'"
                    >
                      <label>Bank Name</label>
                      <input
                        v-model="userPaymentForm.bank"
                        type="tel"
                        class="form-control"
                        placeholder="Bank Name"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.bank">{{
                          userPaymentForm.errors.error.bank[0]
                        }}</span></span
                      ></small
                    >
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                      v-if="userPaymentForm.paymentMode == 'Online'"
                    >
                      <label>Branch</label>
                      <input
                        v-model="userPaymentForm.branch"
                        type="tel"
                        class="form-control"
                        placeholder="Branch"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.branch">{{
                          userPaymentForm.errors.error.branch[0]
                        }}</span></span
                      ></small
                    >
                    </div>

                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                      v-if="userPaymentForm.paymentMode == 'Online'"
                    >
                      <label>IFSC</label>
                      <input
                      minlength="11"
                      maxlength="11"
                        v-model="userPaymentForm.ifsc"
                        type="tel"
                        class="form-control"
                        placeholder="IFSC"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.ifsc">{{
                          userPaymentForm.errors.error.ifsc[0]
                        }}</span></span
                      ></small
                    >
                    </div>
                    <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                      v-if="userPaymentForm.paymentMode == 'Online'"
                    >
                      <label>Account No</label>
                      <input
                      @keydown="accountNoVall"
                      type="tel"
                      maxlength="18"
                      minlength="9"
                        v-model="userPaymentForm.accountno"
                   
                        class="form-control"
                        placeholder="Account No"
                      />
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.accountno">{{
                          userPaymentForm.errors.error.accountno[0]
                        }}</span></span
                      ></small
                    >
                    </div> -->

                    <!-- <div
                      class="col-xl-6 col-md-6 col-lg-6 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Status</label>

                      <select
                        v-model="userPaymentForm.status"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected disabled>Select...</option>
                        <option value="paid">Paid</option>
                      </select>
                    </div> -->

                    <div
                      class="col-xl-12 col-md-12 col-lg-12 col-12"
                      style="padding: 5px 5px 5px 5px"
                    >
                      <label>Remark </label>

                      <textarea
                        v-model="userPaymentForm.notation"
                        placeholder="Remark "
                        class="form-control"
                        rows="3"
                      ></textarea>
                      <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.notation">{{
                          userPaymentForm.errors.error.notation[0]
                        }}</span></span
                      ></small
                    >
                    </div>
                    <!-- @click="thankyouPage" -->
                    <div class="row">
                      <div class="col p-1">
                        <h4 style="color:#00364f"><strong>Charges : ₹ {{serviceCharge}}</strong></h4>
                      </div>
                      <div class="col text-end p-1">
                        
                        <!-- <button
                          v-if="reciptData.amt"
                          type="button"
                          class="btn btn-sm text-white shadow-lg"
                          style="background-color: #208504; margin-right: 10px"
                          data-bs-toggle="modal"
                          data-bs-target="#paymentRecipt"
                        >
                          Payment Recipt
                        </button> -->
                        <button
                          type="submit"
                          class="btn btn-sm text-white shadow-lg"
                          style="background-color: #00364f"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                    <!-- edn row -->
                  </div>
                </form>


                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- payment recipt  -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="paymentRecipt"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Payment Recipt</h5>
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <div class="modal-body">
          <table style="width: 100%; text-transform: capitalize" class="font">
            <tr>
              <th class="alin-left">
                <strong class="heading" style="font-size: 14pt" v-if="userData"
                  >{{ userData.name }}
                </strong>
              </th>
              <th class="alin-right text-end">
                <!-- <strong class="heading">INVOICE</strong> -->
              </th>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>Address: </strong
                  ><span v-if="userData">{{ userData.address }}  {{ userData.city }} <span v-if="userData.state">{{ userData.state.name }}</span> {{ userData.pin }}</span>
                </div>
              </td>
              <td class="alin-right text-end">
                <div style="font-size: 10pt; color: #00364f">
                  <strong># {{ invoiceData.clientServiceNo }}</strong>
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>[M]: </strong
                  ><span v-if="userData"> +91-{{ userData.phone }}, </span>
                </div>
              </td>
              <td class="text-end">
                <div style="font-size: 10pt">
                  <span
                    style="
                      width: 100px;
                      background-color: green;
                      color: white;
                      box-shadow: 0px 0px 5px 0px gray;
                      border-radius: 50px;
                      font-size: 10px;
                      padding: 3px 10px 3px 10px;
                      margin-top: 10px;
                      margin-bottom: 5px;
                    "
                    >paid</span
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>[E]: </strong>
                  <span v-if="userData"> {{ userData.email }}, </span>
                </div>
              </td>
              <td class="text-end">
                <div style="font-size: 10pt; color: #00364f; padding-top: 10px">
                  <strong>BILL TO</strong>
                </div>
              </td>
            </tr>

            <tr>
              <th class="alin-left"><strong class="heading"> </strong></th>
              <th class="text-end" style="color: #00364f; margin-top: -5px">
                <strong class="heading" style="font-size: 14pt" v-if="invoiceData.client"
                  >{{ invoiceData.client.fname }} {{ invoiceData.client.lname }}</strong
                >
              </th>
            </tr>

            <tr>
              <td class="alin-left"></td>
              <td class="text-end">
                <div style="font-size: 10pt; color: #00364f">
                  <strong v-if="invoiceData.business">
                    {{ invoiceData.business.name }}</strong
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left"></td>
              <td class="text-end">
                <div style="font-size: 10pt; color: #00364f" v-if="invoiceData.client">
                  {{ invoiceData.client.address }},{{ invoiceData.client.address2 }} {{ invoiceData.client.city }} <span v-if="invoiceData.client.state">{{ invoiceData.client.state.name }}</span> {{ invoiceData.client.pin }}
                </div>
              </td>
            </tr>
          </table>

          <hr />

          <table
            class="font"
            style="
              width: 100%;
              color: #00364f;
              font-size: 10pt;
              text-transform: capitalize;
            "
          >
            <tr>
              <th class="alin-left" style="width: 10%">Date</th>
              <td class="alin-left">
                {{ dateTime(reciptData.date) }}
              </td>
              <th class="text-end">Total Amount</th>
              <td class="text-end" style="width: 12%">
                Rs.{{ invoiceData.serviceCharge + invoiceData.serviceChargeGst }}
              </td>
            </tr>

            <tr>
              <th class="alin-left text-truncate">Payment Mode:</th>
              <td class="alin-left">{{ reciptData.paymentMode }}</td>
              <th class="text-end">Paid Amount</th>

              <td class="text-end" style="width: 12%">Rs.{{ reciptData.amt }}</td>
            </tr>

            <!-- <tr v-if="reciptData.paymentMode == 'Online'">
              <th class="alin-left">Bank</th>
              <td class="alin-left">{{ reciptData.bank }}</td>
              <th class="text-end">Branch</th>

              <td class="text-end" style="width: 12%">{{ reciptData.branch }}</td>
            </tr>
            <tr v-if="reciptData.paymentMode == 'Online'">
              <th class="alin-left">IFSC</th>
              <td class="alin-left">{{ reciptData.ifsc }}</td>
              <th class="text-end">Account No</th>

              <td class="text-end" style="width: 12%">{{ reciptData.accountno }}</td>
            </tr> -->

            <tr v-if="reciptData.paymentMode == 'Cheque'">
              <th class="alin-left">Cheque No</th>
              <td class="alin-left">{{ reciptData.checkno }}</td>
              <th class="text-end"></th>

              <td class="text-end" style="width: 12%"></td>
            </tr>
          </table>

          <div v-if="reciptData.paymentMode == 'Online'">
          <Strong> Remark:</Strong>
          <div >
         {{ reciptData.notation }}
          </div>
          </div>

          <table
            class="font"
            style="width: 100%; margin-top: 10px; text-transform: capitalize"
          >
            <tr style="background-color: #00364f; font-size: 10pt; color: white">
              <th class="alin-left" style="padding: 5px 5px 5px 5px">Description</th>
              <th class="text-end" style="padding: 5px 5px 5px 5px">Rate</th>
              <th class="text-end" style="padding: 5px 5px 5px 5px">Amount</th>
            </tr>
            <tr style="font-size: 10pt; color: #00364f">
              <td>
                <span v-if="invoiceData.service">{{ invoiceData.service.name }}</span>
                <span v-for="(mod, index) in serviceModules" :key="index"
                  ><span v-if="mod"> ({{ mod.moduleName }})</span></span
                >
              </td>
              <td class="text-end">
                Rs.{{ invoiceData.serviceCharge + invoiceData.serviceChargeGst }}
              </td>
              <td class="text-end">
                Rs.{{ invoiceData.serviceCharge + invoiceData.serviceChargeGst }}
              </td>
            </tr>
          </table>

          <div
            style="
              background-color: #f2f2f2;
              padding: 5px 10px 5px 10px;
              border-radius: 10px;
              margin-top: 30px;
            "
          >
            <table
              style="
                width: 100%;
                font-size: 10pt;
                color: #00364f;
                text-transform: capitalize;
              "
              class="font"
            >
              <tr>
                <td class="text-start">
                  <strong>In words: {{ netAmtInWords }}</strong>
                </td>
                <td class="text-end">
                  <strong style="margin-right: 50px">Total</strong>
                  <strong>
                    Rs.{{
                      invoiceData.serviceCharge + invoiceData.serviceChargeGst
                    }}</strong
                  >
                </td>
              </tr>
            </table>
          </div>

          <div
            class="font"
            style="
              text-align: center;
              margin-top: 30px;
              font-size: 10pt;
              text-transform: capitalize;
            "
          >
            This is Computer Generate Invoice No Signature Required
          </div>

          <div class="text-end"><button
                         
                          type="button"
                          class="btn btn-sm text-white shadow-lg"
                          style="background-color: #f21000; margin-right: 10px"
                          @click="thankyouPage"
                        >
                          Next
                        </button></div>

       
        </div>
        
      </div>
    </div>
  </div>

  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

<script>
import moment from "moment";
// import DocumentUpload from '../../../../components/retailer/comman/DocumentUpload.vue'
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/retailer/comman/Spinner.vue";
import InWordsNumber from "../../../mixins/numberToinword";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Udyam",
  mixins: [InWordsNumber],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      netAmtInWords: '',
      loading: false,
      admin: {},
      serviceName: "",
      category: "",
      client_service_id: null,
      clientservicedocuments: [],
      allDoccs: [],
      uploadDocc_form: {
        client_service_id: null,
        service_document_id: null,
        alt: "",
        caption: "",
        serviceDocument: "",
        cwidth: "",
        cheight: "",
        cx: "",
        cy: "",
      },
      serviceCharge: "",
      charges: {},
      form: new Form({
        photo: "",
        pan: "",
        fname: "",
        lanme: "",
        phone: "",
        email: "",
        dob: null,
        gender: null,
        address: null,
        address2: null,
        alternativePhone: null,
        city: null,
        fatherName: "",
        pin: null,
        state_id: null,
        code: "",
      }),
      reciptData: {},
      userPaymentForm: {
        client_id: "",
        service_id: "",
        client_service_id: "",
        amt: "",
        date: "",
        paymentMode: "Cash",
        txn: "",
        txnRefNo: "",

        checkno: "",
        bank: "",
        ifsc: "",

        branch: "",
        accountno: "",
        notation: "",
        remark: "",
        status: "",
        errors:{}
      },

      userData: {},
      serviceModules: [],
      inWords: "",

      doBDate1: "",
      invoiceData: {},
      totalAmt: null,
      number: null,
      ones: [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ],
      tens: [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ],
      scales: [
        "",
        "",
        "",
        "hundred ",
        "thousand",
        "million",
        "billion",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
        "decillion",
        "undecillion",
        "duodecillion",
        "tredecillion",
        "quatttuor-decillion",
        "quindecillion",
        "sexdecillion",
        "septen-decillion",
        "octodecillion",
        "novemdecillion",
        "vigintillion",
        "centillion",
      ],
    };
  },
  methods: {
    redirectBack() {
      var url = 'udyam-2'


     this.$router.push(`/retailer/${url}`);
      // this.$router.push({ name: "Itr" });
    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      // console.log(currentDateWithFormat);

      var data = event.target.value;
   
      if (type == "doBDate1") {
       
        if (currentDateWithFormat < data) {
        
          console.log(type);
          this.doBDate1 = "false";
          $("#doBDate1").html("<small></small>");
        }
        if (currentDateWithFormat > data) {
          
          $("#doBDate1").html("<small>It should be Not less than current date</small>");
        }
      }


     
      // }
    },
    accountNoVall(e) {
  const inputValue = e.key;

  // Allowing only Backspace key
  if (inputValue === 'Backspace' || inputValue === 'Delete') {
    // Perform your specific action for the Backspace key
    console.log('Backspace key pressed!');
  } else {
    // Blocking other keys, including Delete, non-word characters, and alphabets
    if (/^\W$/.test(inputValue) || /[a-zA-Z]/.test(inputValue)) {
      this.error = true;
      e.preventDefault();
    } else {
      this.error = false;
    }
  }
},
    logofinder(admin) {
      console.log("====logo=====");
      console.log(admin);
      console.log("=========");
      var img;
      if (admin.logo != null) {
        img = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    countDig(totalAmt) {
      var n = totalAmt;
      var count = 0;
      if (n >= 1) ++count;
      while (n / 10 >= 1) {
        n /= 10;
        ++count;
      }
      return count;
      // this.covertNumberTowor(count)
    },

    atOnse19(amt) {
      return this.ones.at(amt);
    },
    atTese99(amt) {
      var n = 0;
      var res = amt;
      n = parseInt(res / 10);
      amt = parseInt(res % 10);
      var txt1 = this.tens.at(n);
      if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    scales999(amt) {
      let count = this.countDig(amt);
      var res = amt;
      var n = parseInt(res / 100);
      var txt1 = this.ones.at(n);
      amt = parseInt(res % 100);
      txt1 = this.atOnse19(n) + " " + this.scales.at(count);
      // console.log(amt)
      if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    valTo9999(amt) {
      let count = this.countDig(amt);
      var res = amt;
      var n = parseInt(res / 1000);
      var txt1 = this.ones.at(n);
      amt = parseInt(res % 1000);
      txt1 = this.atOnse19(n) + " " + this.scales.at(count);
      if (amt > 99 && amt < 1000) {
        txt1 = txt1 + " " + this.scales999(amt);
        return txt1;
      } else if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    valTo99999(amt) {
      let count = this.countDig(amt);
      // console.log('99999')
      if (count == 4 || count == 5) {
        count = 4;
      }
      var res = amt;
      var n = parseInt(res / 1000);
      var txt1 = this.ones.at(n) + " " + this.scales.at(count);
      // console.log(txt1)
      amt = parseInt(res % 1000);
      txt1 = n < 20 ? txt1 : this.atTese99(n) + " " + this.scales.at(count);
      if (amt > 99 && amt < 1000) {
        txt1 = txt1 + " " + this.scales999(amt);
        //    console.log(txt1)
        return txt1;
      } else if (amt > 19 && amt < 100) {
        txt1 = txt1 + " " + this.atTese99(amt);
        return txt1;
      } else if (amt < 20) {
        txt1 = txt1 + " " + this.atOnse19(amt);
        return txt1;
      } else {
        return txt1;
      }
    },
    covertNumberTowor() {
      // console.log('click')
      // console.log(this.totalAmt)
      let count = this.countDig(this.totalAmt);
      // console.log(count)
      if (this.totalAmt < 20) {
        count = 1;
      }
      switch (count) {
        case 1:
          this.inWords = this.atOnse19(this.totalAmt);
          break;
        case 2:
          this.inWords = this.atTese99(this.totalAmt);
          break;
        case 3:
          this.inWords = this.scales999(this.totalAmt);
          break;
        case 4:
          this.inWords = this.valTo9999(this.totalAmt);
          break;
        case 5:
          this.inWords = this.valTo99999(this.totalAmt);
          break;

        default:
          this.inWords = this.totalAmt;
      }
    },
    print() {
      // alert('hello')
      // Get HTML to print from element
      const prtHtml = document.getElementById("printableArea").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    dateTime(value) {
      console.log(value);
      return moment(value).format("DD-MM-YYYY");
    },
    lodaInvoices() {
      var clientService = JSON.parse(localStorage.getItem("service"));
      this.$axios
        .get(`retailer/clientservice/${clientService.client_service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.invoiceData = res.data.data;
          if (res.data.data.admin) {
            this.admin = res.data.data.admin;
          }
          this.conertNumberToWords(this.invoiceData.serviceCharge+this.invoiceData.serviceChargeGst);
          this.totalAmt = this.invoiceData.serviceCharge;
          // console.log('---------------------------------------------')
          // console.log(this.invoiceData.serviceModules)
          var mod = JSON.parse(this.invoiceData.serviceModules);
          // console.log(JSON.parse(this.invoiceData.serviceModules));

          this.$axios
            .post(
              `/retailer/servicemodule`,
              { serviceModulesIds: mod },
              { headers: { Authorization: "Bearer " + localStorage.accessToken } }
            )
            .then((res) => {
              // console.log(res.data)
              this.serviceModules = res.data.data;
            });
          // console.log('---------------------------------------------')

          // mod = unserialize(this.invoiceData.serviceModules);

          // console.log(mod);
          // document.write('<br />'+"Unserialize the above data and go back to original array : " +'<br />');

          this.countDig();
          this.covertNumberTowor();
        });
    },
    loadUserApi() {
      this.$axios
        .post(
          `retailer/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;
        });
    },

    // area

    clientPayServiceAmount() {
      var clientService = JSON.parse(localStorage.getItem("service"));
      this.userPaymentForm.client_id = clientService.client_id;
      this.userPaymentForm.client_service_id = clientService.client_service_id;
      this.userPaymentForm.service_id = clientService.service_id;
      this.loading = true;
      this.$axios
        .post(`retailer/clientpayment`, this.userPaymentForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          this.reciptData = res.data.data;
          this.userPaymentForm = {};

          toast.success("Save Successfully", {
            autoClose: 1000,
          });

          $("#paymentRecipt").modal("show");
        })
        .finally(() => (this.loading = false))
     
        .catch((error) => {
          // error.data.message;
          this.msgError = error.response.data;
          this.userPaymentForm.errors = error.response.data;
          console.log(error.response.data);
        });
    },

    //         downloadImage(url) {
    //   fetch(url, {
    //     mode : 'no-cors',
    //   })
    //     .then(response => response.blob())
    //     .then(blob => {
    //     let blobUrl = window.URL.createObjectURL(blob);
    //     let a = document.createElement('a');
    //     // a.download = url.replace("/^.*[\\\/]/", '');
    //     a.href = blobUrl;
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //   })
    // },
    thankyouPage() {
      $("#paymentRecipt").modal("hide");
      this.$router.push({ name: "ThankYou" });
    },
    downloadImg(link) {
      var url;
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/thumbs/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/thumbs/" +
          link.document;
      }
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/" +
          link.document;
      }
      // console.log('downloading : ', url);
      return url;
    },
    clientserviceDocumentLoad() {
      this.$axios
        .get(
          `retailer/clientservicedocument?client_service_id=${this.client_service_id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log('-------clientservice_id--------')
          //   console.log(res.data.data.data)
          this.clientservicedocuments = res.data.data.data;
        });
    },
    uploadDocuments(event, docc) {
      this.uploadDocc_form.serviceDocument = event.target.files["0"];
      this.uploadDocc_form.service_document_id = docc.id;
      this.uploadDocc_form.alt = docc.document;
      this.uploadDocc_form.caption = docc.document;

      // console.log('-------------------------------------------------------------------------------')

      const formData = new FormData();
      const keys = Object.keys(this.uploadDocc_form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.uploadDocc_form[keys[n]] !== null) {
          formData.append(keys[n], this.uploadDocc_form[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios 
        .post("retailer/clientservicedocument", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          // alert(res.data)
      
          toast.success("The form was sent successfully.", {
          autoClose: 1000,
        });
          this.clientserviceDocumentLoad();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: error.response.data.error.serviceDocument[0],
          });
        });

    },
    LoadDocuments() {
      var proprietorship = {};
      proprietorship = JSON.parse(localStorage.getItem("service"));
      var service_id;
      service_id = proprietorship.service_id;
      this.$axios
        .get(`retailer/servicedocument?service_id=${service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          //   console.log(res.data)
          this.allDoccs = res.data.data.data;
        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {


      var proprietorship = {};
      proprietorship = 'by'
   
      proprietorship = JSON.parse(localStorage.getItem("service"));

if(proprietorship == 'by' || proprietorship == 'null' || proprietorship == null){
  this.$router.push(`/retailer/all-services`);
}else{

  this.clientserviceDocumentLoad();
      this.LoadDocuments();

      this.lodaInvoices();
      this.loadUserApi();

   
      // if (proprietorship.serviceCharge) {
      this.serviceName = proprietorship.serviceName;
      this.category = proprietorship.categoryName;
      this.serviceCharge = proprietorship.serviceCharge;
      this.uploadDocc_form.client_service_id = proprietorship.client_service_id;
      this.client_service_id = proprietorship.client_service_id;


  

}




    
      // }
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.accordian-plus {
  width: 20px;
  height: 20px;
  stroke: red;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  font-family: "Poppins", sans-serif;
}

.p-text {
  color: #fff;
  /* font-family: poppins, Sans-serif; */
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.bg-image {
  background-color: #fff;
  background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 0 120px;
  /* text-align: center; */
  color: white;
  /* background-size: 100% 100%;
   width: 100%; */
  font-family: "Poppins", sans-serif;
}

.h2-heading {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 767px) {
  .h2-heading {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
  }
}

.heading {
  color: #00364f;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 20px 0;
}

.inner-card {
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px 5px 5px 5px;
  padding: 10px;
}

.heading-red {
  color: #f21300;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
</style>
